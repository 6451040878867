<script setup lang="ts">
import { useSiteStore } from '~/stores/site.store';
import { useAuthStore } from '~/stores/auth.store';
import { useEventListener } from '@vueuse/core';
import useMessageHandler from '~/composables/useMessageHandler';
import { globalKeys } from 'assets/data/global-keys';

const panelCultureCode: string = 'en-US';
const panel = ref();
const runtimeConfig = useRuntimeConfig();
const auth = useAuthStore();
const site = useSiteStore();

useHead({
  script: [
    {
      src: runtimeConfig.public.notificationPanelUrl,
      type: 'module',
    },
  ],
});

onMounted(() => {
  useEventListener(
    panel.value,
    'handleNotificationActions',
    (event: CustomEvent) => {
      const action = event.detail.action;
      const actionValue = event.detail.actionValue;
      useMessageHandler({ action, actionValue });
      site.explicitToggleFeature({
        feature: 'displayNotifications',
        toggle: false,
      });
      auth.toggleAccountModal(false);
      panel.value.removeAttribute('opened');
    },
  );
  useEventListener(panel.value, 'closePanel', () => {
    site.explicitToggleFeature({
      feature: 'displayNotifications',
      toggle: false,
    });
    panel.value.removeAttribute('opened');
  });

  useEventListener(
    panel.value,
    'updateNotificationsCount',
    (event: CustomEvent) => {
      site.setUnreadNotificationsCount(event.detail.unread);
    },
  );
});

watch(
  () => site.featureDisplay({ feature: 'displayNotifications' }),
  (value) =>
    !value
      ? panel.value.removeAttribute('opened')
      : panel.value.setAttribute('opened', ''),
);
</script>

<template>
  <notifications-panel
    ref="panel"
    id="notifications-panel"
    date-limit-in-months="6"
    licence="jackpotcityafrica"
    storage-type="cookie"
    :key-name="globalKeys.authKeys.accessToken"
    opened="false"
    enable-push-notifications="on"
    class="hydrated"
    limit="20"
    :preferred-color-scheme="useColorMode().preference"
    :region-code="site?.getRegionCode"
    :culture-code="panelCultureCode"
  ></notifications-panel>
</template>
